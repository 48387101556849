import { Alert, Clickable, H5, H6, P, Tooltip } from '@ahmdigital/ui';
import getOpeningHours from '@ahmdigital/logic/lib/utils/get-opening-hours';
import React from 'react';
import styled from 'styled-components';

import { FormFieldsForComponentProps } from '../../../types';
import { IS_MEMBER_FIELD_OPTIONS, MEMBER_CALLBACK_REASONS, SHARED_FIELD_PROPS } from '../../../constants';
import { LEAD_TYPES } from '../../../../../models/lead-capture/constants';
import { URLS } from '../../../../../models/url/constants';
import Animate from '../../../../animate';
import constants from '../../../../../ahm-constants';
import DECLARATION_LABEL from '../../declaration-label';
import FormikField from '../../../../formik-field-with-scroll-margin';
import HorizontalRule from '../../../../../elements/horizontal-rule';
import LiveChatStatus from '../../../../live-chat/live-chat-status';
import styles from '../../../styles.module.sass';
import useLiveChat from '../../../../live-chat/use-live-chat';

const ThinSolidHorizontalRule = styled(HorizontalRule)`
  border-color: ${({ theme }) => theme.colors.grey20};
  border-top-width: 2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const CallbackUnavailable = () => {
  const { isOnline } = useLiveChat();

  return (
    <>
      <Alert
        analyticsLabel="Callback unavailable"
        heading="We’re sorry but we can’t call you back right now. "
        level="warning"
      >
        Callbacks are unavailable for your selected reason.
      </Alert>
      <H5 variant="marginBottomSpacer10">But we’re here to help</H5>
      <H6>Message us</H6>
      <P>
        {constants.CONTACT.OPENING_HOURS.LIVE_CHAT.WIDGET}
        <br />
        Excluding national public holidays
      </P>
      <LiveChatStatus isOnline={isOnline} />
      <ThinSolidHorizontalRule />
      <H6>Manage your cover online</H6>
      <P>
        Check annual limits, claim for extras, update your details, find information about your policy and more.
        <Clickable to={URLS.MEMBERS.HOME}>Member log in</Clickable>
      </P>
      <ThinSolidHorizontalRule />
      <H6>Call us</H6>
      <P>
        <Clickable to={`tel:${constants.CONTACT.PHONE.STANDARD}`}>{constants.CONTACT.PHONE.PRETTY}</Clickable>
        <br />
        {getOpeningHours(constants.AHM_SERVICE_NAME.SALES)}
        <br />
        Excluding national public holidays
        <br />
        Calling from overseas?{' '}
        <Clickable to={`tel:${constants.CONTACT.PHONE.INTERNATIONAL_STANDARD}`}>
          {constants.CONTACT.PHONE.INTERNATIONAL_PRETTY}
        </Clickable>
      </P>
      <ThinSolidHorizontalRule />
      <P>
        Or visit our <Clickable to={URLS.HELP.HOME}>help section</Clickable>, we may have already answered your
        question.
      </P>
    </>
  );
};

const RequestACallCommonFormFields = ({ values, setFieldValue, setFieldTouched }: FormFieldsForComponentProps) => (
  <>
    <FormikField
      {...SHARED_FIELD_PROPS}
      name="isMember"
      label="Are you a current ahm member?"
      inputVariant="button"
      type="radioGroup"
      options={IS_MEMBER_FIELD_OPTIONS}
      onBlur={() => setFieldTouched('isMember', true, false)}
      onChange={(value: boolean) => setFieldValue('isMember', value)}
    />
    <Animate name="slideIn" toggle>
      {values?.isMember && (
        <div>
          <FormikField
            {...SHARED_FIELD_PROPS}
            inputVariant={['button', 'fullWidth']}
            label="What is the reason for your callback?"
            name="memberCallbackReason"
            options={[{ text: 'Please select', value: '' }, ...MEMBER_CALLBACK_REASONS]}
            size="auto"
            type="dropdown"
          />
        </div>
      )}
    </Animate>
  </>
);

const RequestACallContactFormFields = ({ component }: Pick<FormFieldsForComponentProps, 'component'>) => (
  <>
    <FormikField {...SHARED_FIELD_PROPS} label="Name" name="name" type="text" isRequired />
    <FormikField {...SHARED_FIELD_PROPS} label="Contact number" name="phone" type="tel" isRequired />
    <FormikField
      {...SHARED_FIELD_PROPS}
      label="Email address"
      maxLength="255"
      name="email"
      placeholder="name@example.com"
      type="email"
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      hasContainerID
      fieldLabel={DECLARATION_LABEL}
      name={`privacyAgreement-${component}`}
      type="checkbox"
      isRequired
      variant={styles.checkbox}
    />
  </>
);

const RequestACallFormFields = ({ component, setFieldTouched, setFieldValue, values }: FormFieldsForComponentProps) => {
  if (!values?.isMember) {
    return (
      <>
        <RequestACallCommonFormFields
          component={component}
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
        <RequestACallContactFormFields component={component} />
        <FormikField
          {...SHARED_FIELD_PROPS}
          fieldLabel="Please contact me about health insurance, discounts, promotions from ahm & partners including health and other insurance products and services."
          name={`marketingOptIn-${component}`}
          type="checkbox"
          isRequired={component === LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component}
          variant={component === LEAD_TYPES.REQUEST_A_CALL.component && styles.checkbox}
        />
      </>
    );
  }

  if (!values?.memberCallbackReason || values?.memberCallbackReason === 'Wanting to leave') {
    return (
      <>
        <RequestACallCommonFormFields
          component={component}
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
        <FormikField
          {...SHARED_FIELD_PROPS}
          name="memberId"
          maxLength="8"
          label="What is your member ID?"
          tooltip={
            <Tooltip>
              <P>This is an 8 digit number that you can find on your digital or physical member card</P>
            </Tooltip>
          }
          type="tel"
          isRequired
        />
        <RequestACallContactFormFields component={component} />
      </>
    );
  }

  return (
    <>
      <RequestACallCommonFormFields
        component={component}
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <CallbackUnavailable />
    </>
  );
};

export default RequestACallFormFields;
