import browserUtil from '../browser';

const scrollToElementWithId = (id: string) => {
  const element = browserUtil.getDocument().getElementById(id);
  if (!element) {
    return;
  }

  element.scrollIntoView({ behavior: 'smooth' });
};
export default scrollToElementWithId;
