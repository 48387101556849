import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { flow, isNil, merge, omitBy } from 'lodash/fp';
import { Formik } from 'formik';
import React from 'react';

import { LeadCaptureFormProps } from './types';
import { LeadInput } from '../../types/leads';
import { RootState } from '../../reducers';
import { useCookieContext } from '../../contexts/cookie';
import adjustFormValuesForLeadType from './utils/adjust-form-values-for-lead-type';
import createLead from '../../utils/create-lead';
import generateSchema from './schema';
import LeadCaptureFormActions from '../../actions/lead-capture-form';
import LeadForm from './components/lead-form';
import selectLeadCapture from '../../selectors/select-lead-capture';

const checkEmpty = (value: boolean | string | number) => value === '' || isNil(value);

const LeadCaptureForm = ({
  analyticsLabel,
  additionalLeadFields = {},
  actions,
  leadInput,
  getEmail,
  leadType,
  onSubmit,
  sourceType,
  submitButtonText,
  captureFormState,
  isLoading,
}: LeadCaptureFormProps) => {
  const serverCookieValue = useCookieContext() as string;
  const { packagedSchema } = generateSchema(leadType.component, serverCookieValue);
  return (
    <Formik
      {...packagedSchema}
      // @ts-expect-error - Automatic, Please fix when editing this file
      onSubmit={async (values) => {
        const adjustedValues = adjustFormValuesForLeadType({ leadType, values });

        if (onSubmit) {
          onSubmit(adjustedValues);
        }

        const leadFormData = flow(
          merge(additionalLeadFields),
          merge(leadInput),
          omitBy(checkEmpty),
        )(adjustedValues) as LeadInput;

        try {
          const leadId = await createLead({ leadInput: leadFormData, sourceType });
          actions.leadCaptureForm.leadSuccess({ id: leadId, leadType });

          if (getEmail) {
            getEmail(leadFormData.email);
          }
        } catch (error) {
          actions.leadCaptureForm.leadFail({ leadType });
        }
      }}
    >
      <LeadForm
        analyticsLabel={analyticsLabel}
        captureFormState={captureFormState}
        leadType={leadType}
        submitButtonText={submitButtonText}
        isLoading={isLoading}
      />
    </Formik>
  );
};

// @ts-expect-error - Automatic, Please fix when editing this file
const mapDispatchToProps = (dispatch) => ({
  actions: {
    leadCaptureForm: bindActionCreators(LeadCaptureFormActions, dispatch),
  },
});

const mapStateToProps = (state: RootState) => ({
  // passing through empty object as second parameter because the selectLeadCapture selector requires it for
  // use with the abandoned cart checkout action (this should be decoupled from the selector)
  leadInput: selectLeadCapture(state, {}),
});

// @ts-expect-error - Automatic, Please fix when editing this file
export default connect(mapStateToProps, mapDispatchToProps)(LeadCaptureForm);
