import React from 'react';

import { FormFieldsForComponentProps } from '../../../types';
import { LEAD_TYPES } from '../../../../../models/lead-capture/constants';
import { SHARED_FIELD_PROPS } from '../../../constants';
import DECLARATION_LABEL from '../../declaration-label';
import FormikField from '../../../../formik-field-with-scroll-margin';
import styles from '../../../styles.module.sass';

type AbandonedCartPreBuyFormFieldsProps = Pick<FormFieldsForComponentProps, 'component'>;

const AbandonedCartPreBuyFormFields = ({ component }: AbandonedCartPreBuyFormFieldsProps) => (
  <>
    <FormikField
      {...SHARED_FIELD_PROPS}
      hasContainerID
      isRequired
      label="Email address"
      maxLength="255"
      name="email"
      placeholder="name@example.com"
      type="email"
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      hasContainerID
      fieldLabel={DECLARATION_LABEL}
      name={`privacyAgreement-${component}`}
      type="checkbox"
      isRequired
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      fieldLabel="Please contact me about health insurance, discounts, promotions from ahm & partners including health and other insurance products and services."
      name={`marketingOptIn-${component}`}
      type="checkbox"
      isRequired={component === LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component}
      variant={component === LEAD_TYPES.REQUEST_A_CALL.component && styles.checkbox}
    />
  </>
);

export default AbandonedCartPreBuyFormFields;
