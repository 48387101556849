import { Clickable } from '@ahmdigital/ui';
import React from 'react';

import constants from '../../../../ahm-constants';

const DECLARATION_LABEL = (
  <>
    I agree to the terms of ahm’s{' '}
    <Clickable to={constants.URLS.SALES.PRIVACY} inNewTab>
      privacy policy
    </Clickable>
  </>
);

export default DECLARATION_LABEL;
