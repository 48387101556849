import { getOr, isEmpty } from 'lodash/fp';
import cookie from 'cookie';
import moment from 'moment';

import browserUtil from '../browser';
import config from '../../config';
import getIsServer from '../get-is-server';
import logger from '../../logging';

const KEY = '_mem';
const MAX_AGE = moment.duration(1, 'year').asSeconds();

type InsuranceType = 'health' | 'home' | 'car' | 'income-protection' | 'life' | 'travel';

const setCookie = (key: string, value: string) => {
  const doc = browserUtil.getDocument();

  (doc as any).cookie = cookie.serialize(key, value, {
    domain: config.get('cookie:domain'),
    maxAge: MAX_AGE,
    path: '/',
  });
};

export const setCookieForMembers = (insuranceType: InsuranceType, meta = {}) => {
  const doc = browserUtil.getDocument();

  try {
    // Set the new _mem cookie for all product memberships
    const cookieObj = cookie.parse(getOr('', 'cookie', doc));
    const data = JSON.parse(getOr('{}', KEY, cookieObj));
    setCookie(KEY, JSON.stringify({ ...data, [insuranceType]: meta }));
  } catch (err) {
    logger.getLogger().error('Setting member cookie failed', { err, insuranceType });
  }
};

type Options = { serverCookieValue?: string };

export const getCookieForMembers = (insuranceType: InsuranceType, { serverCookieValue }: Options = {}) => {
  const doc = browserUtil.getDocument();
  const isServer = getIsServer();

  try {
    const cookieRawValue = isServer && !isEmpty(serverCookieValue) ? serverCookieValue : getOr('', 'cookie', doc);
    const cookieObj = cookie.parse(cookieRawValue);
    const data = JSON.parse(getOr('{}', KEY, cookieObj));
    const cookieValue = data[insuranceType];
    if (cookieValue) {
      return cookieValue;
    }
  } catch (err) {
    logger.getLogger().error('Getting member cookie failed', { err, insuranceType });
  }
  return null;
};
