import {
  checked,
  conditional,
  email,
  maxLength,
  minLength,
  packageSchemaForFormik,
  phone,
  required,
} from '@ahmdigital/form-validation';
import { get, includes } from 'lodash/fp';

import { getCookieForMembers } from '../../../utils/cookie-for-members';
import { LEAD_TYPES } from '../../../models/lead-capture/constants';

// undefined passed into the required validator won't override the default validation message
const DEFAULT_VALIDATION_ERROR = undefined;
const { SAVE_AND_RETRIEVE_QUOTE, ABANDONED_CART_PRE_BUY_FORM, REQUEST_A_CALL } = LEAD_TYPES;

const REQUIRED_EMAIL_TYPES = [SAVE_AND_RETRIEVE_QUOTE.component, ABANDONED_CART_PRE_BUY_FORM.component];
const REQUIRED_PHONE_TYPES = [REQUEST_A_CALL.component];
const REQUIRED_NAME_TYPES = [REQUEST_A_CALL.component, SAVE_AND_RETRIEVE_QUOTE.component];
const REQUIRED_PRIVACY_POLICY_TYPES = [REQUEST_A_CALL.component, ABANDONED_CART_PRE_BUY_FORM.component];

const getCustomEmailMessage = () => 'Enter a valid email address';

const getCustomCheckboxMessage = () => 'Select the required checkbox';

// @ts-expect-error - Automatic, Please fix when editing this file
const isRequired = (component, leadComponentType, message) =>
  includes(component, leadComponentType) ? required(message) : '';

// @ts-expect-error - Automatic, Please fix when editing this file
const generateSchema = (component, serverCookieValue?: string) => {
  const memberId = get('id', getCookieForMembers('health', { serverCookieValue }));

  const schema = {
    email: {
      initialValue: '',
      linkPhrases: ['email address'],
      validation: [
        email(getCustomEmailMessage()),
        maxLength(255),
        isRequired(component, REQUIRED_EMAIL_TYPES, getCustomEmailMessage()),
      ],
    },
    isMember: {
      initialValue: memberId !== undefined,
    },
    memberCallbackReason: {
      initialValue: '',
      validation: conditional(
        ({ isMember }: { isMember: boolean }) => isMember && component === REQUEST_A_CALL.component,
        [required()],
      ),
    },
    memberId: {
      initialValue: memberId ?? '',
      validation: conditional(
        ({ isMember }: { isMember: boolean }) => isMember && component === REQUEST_A_CALL.component,
        [required(), minLength(8, 'We’re missing something … it should be 8 numbers')],
      ),
    },
    [`marketingOptIn-${component}`]: {
      initialValue: false,
      validation: [
        conditional(
          () => component === LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component,
          [required(), checked(getCustomCheckboxMessage())],
        ),
      ],
    },
    name: {
      initialValue: '',
      validation: [isRequired(component, REQUIRED_NAME_TYPES, DEFAULT_VALIDATION_ERROR), maxLength(200)],
    },
    phone: {
      initialValue: '',
      validation: [isRequired(component, REQUIRED_PHONE_TYPES, DEFAULT_VALIDATION_ERROR), phone()],
    },
    [`privacyAgreement-${component}`]: {
      initialValue: !includes(component, REQUIRED_PRIVACY_POLICY_TYPES),
      linkPhrases: ['required checkbox'],
      validation: [
        conditional(
          () => includes(component, REQUIRED_PRIVACY_POLICY_TYPES),
          [required(), checked(getCustomCheckboxMessage())],
        ),
      ],
    },
  };

  return { packagedSchema: packageSchemaForFormik(schema), rawSchema: schema };
};

export default generateSchema;
