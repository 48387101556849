import { includes } from 'lodash/fp';
import { P } from '@ahmdigital/ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type IndicatorProps = {
  isOnline: boolean;
};

export const Indicator = styled.div<IndicatorProps>`
  height: 10px;
  width: 10px;
  background-color: ${({ isOnline, theme: { colors } }) => (isOnline ? colors.purple : colors.grey50)};
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const Label = styled(P).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !includes(prop, ['isOnline']) && defaultValidatorFn(prop),
})<IndicatorProps>`
  color: ${({ isOnline, theme: { colors } }) => (isOnline ? colors.textBlack : colors.grey50)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
