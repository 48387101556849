/**
 * Animate
 * built using https://facebook.github.io/react/docs/animation.htm
 * and utilising custom classes https://facebook.github.io/react/docs/animation.html#custom-classes.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
import { CSSTransitionGroup } from 'react-transition-group';
import React from 'react';

import styles from './styles.module.sass';

type AnimateProps = {
  appear?: boolean;
  appearTimeout?: number;
  children?: React.ReactNode;
  enterTimeout?: number;
  leaveTimeout?: number;
  name?: string;
  toggle?: boolean;
};

const Animate = ({ appear, appearTimeout, children, enterTimeout, leaveTimeout, name, toggle }: AnimateProps) => {
  let defaultEnterTimeout;
  let defaultLeaveTimeout;
  let defaultAppearTimeout;
  let transitionName;

  // get animation name
  // check is is an appear (on initial mounting) animation
  // notes: https://facebook.github.io/react/docs/animation.html#animate-initial-mounting
  if (appear) {
    transitionName = {
      appear: styles[`${name}Appear`],
      appearActive: styles[`${name}AppearActive`],
    };
  } else {
    transitionName = {
      enter: styles[`${name}Enter`],
      enterActive: styles[`${name}EnterActive`],
      leave: toggle ? styles[`${name}ToggleLeave`] : styles[`${name}Leave`],
      leaveActive: toggle ? styles[`${name}ToggleLeaveActive`] : styles[`${name}LeaveActive`],
    };
  }

  // get transition times based on associated animation. this maps the the
  // transition’s duration property, or in the instance of appear, the
  // duration + delay + 100ms buffer to allow the animation to end smoothly
  switch (name) {
    case 'fade':
      defaultEnterTimeout = 700; // delay 200, duration 400, buffer 100
      defaultLeaveTimeout = 500; // duration 400, buffer 100
      defaultAppearTimeout = 700; // delay 200, duration 400, buffer 100
      break;
    case 'slideIn':
      defaultEnterTimeout = 700; // delay 200, duration 400, buffer 100
      defaultLeaveTimeout = 500; // duration 400, buffer 100
      defaultAppearTimeout = 700; // delay 200, duration 400, buffer 100
      break;
    case 'overlayFade':
      defaultEnterTimeout = 500; // delay 100, duration 300, buffer 100
      defaultLeaveTimeout = 800; // delay 400 duration 300, buffer 100
      break;
    case 'modalSlideIn':
      defaultEnterTimeout = 800; // delay 400, duration 300, buffer 100
      defaultLeaveTimeout = 400; // duration 300, buffer 100
      break;
    default:
      defaultEnterTimeout = 700;
      defaultLeaveTimeout = 500;
      defaultAppearTimeout = 700;
      break;
  }

  // transitionProps setup and overwrites
  const transitionProps = {
    transitionName,
  };

  // if it is an appear animation...
  if (appear) {
    // @ts-expect-error - Automatic, Please fix when editing this file
    transitionProps.transitionAppearTimeout = appearTimeout || defaultAppearTimeout; // eslint-disable-line react/destructuring-assignment
    // @ts-expect-error - Automatic, Please fix when editing this file
    transitionProps.transitionAppear = true;
    // @ts-expect-error - Automatic, Please fix when editing this file
    transitionProps.transitionEnter = false;
    // @ts-expect-error - Automatic, Please fix when editing this file
    transitionProps.transitionLeave = false;
  } else {
    // @ts-expect-error - Automatic, Please fix when editing this file
    transitionProps.transitionEnterTimeout = enterTimeout || defaultEnterTimeout; // eslint-disable-line react/destructuring-assignment
    // @ts-expect-error - Automatic, Please fix when editing this file
    transitionProps.transitionLeaveTimeout = leaveTimeout || defaultLeaveTimeout; // eslint-disable-line react/destructuring-assignment
  }

  return <CSSTransitionGroup {...transitionProps}>{children}</CSSTransitionGroup>;
};

Animate.defaultProps = {
  appear: undefined,
  appearTimeout: undefined,
  children: undefined,
  enterTimeout: undefined,
  leaveTimeout: undefined,
  name: undefined,
  toggle: undefined,
};

export default Animate;
