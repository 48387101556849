import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.sass';

type HorizontalRuleProps = {
  className?: string;
};

const HorizontalRule = ({ className }: HorizontalRuleProps) => (
  <hr className={classNames(styles.horizontalRule, className)} />
);

HorizontalRule.defaultProps = {
  className: null,
};

export default HorizontalRule;
