import { Action, Reducer } from 'redux';
import { get, set } from 'lodash/fp';

import { BUILD_YOUR_COVER_SERVICES_CHANGE, BUILD_YOUR_COVER_STEP_CHANGE } from '../../actions/action-types';
import { Service } from '../../types/__codegen__/ahm-api/graphql';

type SelectServices = Action & {
  productType: 'hospital' | 'extras';
  selectedServices: Service['serviceId'][];
};

type ChangeStep = Action & {
  activeStep: string;
};

export type ActionType = SelectServices | ChangeStep;

type StateType = {
  activeStep: string | null;
  selectedServices: {
    extras: Service['serviceId'][];
    hospital: Service['serviceId'][];
  };
};

const initialState = {
  activeStep: null,
  selectedServices: {
    extras: [],
    hospital: [],
  },
} as StateType;

// eslint-disable-next-line default-param-last -- this is a conflict between eslint and redux
const buildYourCoverReducer: Reducer<StateType, ActionType> = (state = initialState, action): StateType => {
  const reducers = {
    [BUILD_YOUR_COVER_SERVICES_CHANGE]: () => {
      const { productType, selectedServices } = action as SelectServices;
      return set(['selectedServices', productType], selectedServices, state);
    },

    [BUILD_YOUR_COVER_STEP_CHANGE]: () => ({
      ...state,
      activeStep: (action as ChangeStep).activeStep,
    }),
  };

  const reducer = get(action.type, reducers);
  return reducer ? reducer() : state;
};

export default buildYourCoverReducer;
