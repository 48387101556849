import styles from '../styles.module.sass';

export const IS_MEMBER_FIELD_OPTIONS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
] as const;

export const SHARED_FIELD_PROPS = {
  size: 'auto',
  variant: styles.field,
} as const;

export const MEMBER_CALLBACK_REASONS = [
  {
    text: 'General enquiry',
    value: 'General enquiry',
  },
  {
    text: 'Review my cover',
    value: 'Review my cover',
  },
  {
    text: 'Wanting to leave',
    value: 'Wanting to leave',
  },
  {
    text: 'Hospital Admission/Doctors bills',
    value: 'Hospital Admission/Doctors bills',
  },
] as const;
