import { flow, forEach, includes, map, merge, noop, range, replace, values } from 'lodash/fp';
import {
  optimize as Optimize,
  pubsub as Pubsub,
  salesforce as Salesforce,
  tagmanager as TagManager,
} from '@ahmdigital/analytics';

import { DIMENSIONS, USER_TYPE } from './google/constants';
import config from '../config';
import constants from '../ahm-constants';
import ecommerceHandler from './ecommerce-handler';
import ecommerceHandlerGA4 from './ecommerce-handler-ga4';
import GA4Analytics from './ga4-analytics';
import getCustomerAgeBracket from '../utils/get-customer-age-bracket';
import getIsServer from '../utils/get-is-server';
import getMemberNumber from '../utils/get-oms-member-number';
import GoogleAnalytics from './google';
import selectLivechatConversationInfo from '../selectors/select-livechat-conversation-info';
import selectOffers from '../selectors/select-offers';
import uncapFpIterator from '../../utils/uncap-fp-iterator';

const forEachUncapped = uncapFpIterator(forEach);

const nonInteractionActions = ['LeadCapture'];
const {
  ANALYTICS: { EVENTS, GA4_DIMENSIONS },
} = constants;
const ecommerceGA4Event = 'ecommerceGA4';
// Do not remove ecommerceGA4 from analyticsBlockList as it will pass sensitive information through Tag Manager
const analyticsBlockList = [ecommerceGA4Event];

// Handle client / server by giving mock object to SERVER
const getAnalytics = () => {
  const isServer = getIsServer();
  if (!isServer) {
    if (process.env.NODE_ENV === 'test') {
      // @NOTE: The GA script in src/client/analytics/google/index.js looks for an existing script tag
      // to insert before. We need to add this manually when running tests otherwise many will fail.
      // @ts-expect-error - Automatic, Please fix when editing this file
      document.getElementsByTagName('head')[0].appendChild(document.createElement('script'));
    }

    // Initialise instances of outlets
    const google = new GoogleAnalytics(config.get('google:analytics:trackingId'));
    TagManager.initialise(config.get('google:tagManager:containerId'));
    Optimize.initialise(config.get('google:optimize:containerId'));
    GA4Analytics.initialise();
    Salesforce.initialise(config.get('salesforce:url'), true);

    // Initialise instance of analytics
    const pubsub = new Pubsub();
    // @NOTE: This is a placeholder, until the store.getState method overrides it in client.jsx
    pubsub.getState = () => ({});

    /* ECOMMERCE PUBLISHER */
    // @param`type` can be 'impression', 'select', 'view', 'checkout', 'payment', 'purchase'
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.ecommerce = (type, data) => {
      pubsub.publish('ecommerce', { ...data, type });
    };

    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.ecommerceGA4 = (type, data) => {
      pubsub.publish(ecommerceGA4Event, { ...data, type });
    };

    /* SUBSCRIBERS */
    // Subscribe to events
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe('*', (eventName, data) => {
      if (eventName === 'action') {
        const { action, component, label } = data;
        if (component === 'element' && includes('validation error', action)) {
          GA4Analytics.submit({
            data: {
              errorMessage: label,
              // eslint-disable-next-line camelcase
              form_page: window.location.pathname,
            },
            name: EVENTS.ECOMMERCE_GA4.UNSUCCESSFUL_FORM_SUBMIT,
          });
        }
      }

      // It is critical that this tag manager event does not fire for ecommerceGA4 as it will pass through sensitive information
      // EcommerceGA4 events are handled elsewhere
      if (!includes(eventName, analyticsBlockList)) {
        TagManager.event(eventName, data);
      }
    });
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe('userId', (eventName, data) => GoogleAnalytics.userId(data));
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe('userId', (eventName, data) => GA4Analytics.userId(data));

    // Subscribe to pageviews, send to google analytics
    // @NOTE: The first page view likely does not have the rehydrated custom dimensions on it.
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe('pageview', (eventName, data) => {
      GoogleAnalytics.pageview(data.location);
      TagManager.event('optimize.activate');
    });

    const deriveGA4Dimensions = () => {
      const state = pubsub.getState();
      const cart = state.cart || {
        product: {},
      };
      const conversationInfo = selectLivechatConversationInfo(state);
      const customer = state.customer || {};
      const dimensions = {
        [GA4_DIMENSIONS.AGE_BRACKET]: getCustomerAgeBracket(customer),
        [GA4_DIMENSIONS.AGENT_ID]: conversationInfo.agentId,
        [GA4_DIMENSIONS.CAMPAIGN_ID]: conversationInfo.campaignId,
        [GA4_DIMENSIONS.CONVERSATION_ID]: conversationInfo.conversationId,
        [GA4_DIMENSIONS.ENGAGEMENT_ID]: conversationInfo.engagementId,
        [GA4_DIMENSIONS.HOSPITAL_TIER]: cart.product.hospitalTier || null,
        [GA4_DIMENSIONS.INCOME_TIER]: customer.incomeTier,
        [GA4_DIMENSIONS.INCOME_TYPE]: customer.incomeType,
        [GA4_DIMENSIONS.ITEM_ID]: cart.product.id,
        [GA4_DIMENSIONS.ITEM_NAME]: cart.product.name,
        [GA4_DIMENSIONS.PAYMENT_FREQUENCY]: customer.frequency,
        [GA4_DIMENSIONS.PAYMENT_METHOD]: customer.paymentMethod,
        [GA4_DIMENSIONS.PRODUCT_TYPE]: cart.product.type,
        [GA4_DIMENSIONS.REBATE]: customer.rebate ? 'true' : 'false',
        [GA4_DIMENSIONS.SCALE]: customer.scale,
        [GA4_DIMENSIONS.SKILL_NAME]: conversationInfo.skillName,
        [GA4_DIMENSIONS.STATE]: replace(/\s/g, '-', customer.state),
        [GA4_DIMENSIONS.SWITCHING]: customer.switching,
        [GA4_DIMENSIONS.VISITOR_ID]: conversationInfo.visitorId,
      };

      const memberNumber = getMemberNumber();
      if (memberNumber) {
        // @ts-expect-error - Automatic, Please fix when editing this file
        dimensions[`${GA4_DIMENSIONS.MEMBERSHIP_NEW_OR_EXISTING}`] = USER_TYPE.member;
        // @ts-expect-error - Automatic, Please fix when editing this file
        dimensions[`${GA4_DIMENSIONS.MEMBERSHIP_ID}`] = memberNumber;
      } else {
        // @ts-expect-error - Automatic, Please fix when editing this file
        dimensions[`${GA4_DIMENSIONS.MEMBERSHIP_NEW_OR_EXISTING}`] = USER_TYPE.prospect;
      }

      // Populate promo code dimensions
      const promos = selectOffers(state);

      // eslint-disable-next-line lodash-fp/no-unused-result
      flow(
        (items) => merge(items, values(promos)),
        map('code'),
        // @ts-expect-error - Automatic, Please fix when editing this file
        forEachUncapped((code, index) => {
          dimensions[GA4_DIMENSIONS[`PROMO_CODE_${index}`]] = code;
        }),
      )(range(0, 4));

      return dimensions;
    };

    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe('view', (eventName, data) => {
      const category = data.component || data.category || 'unknown';
      const label = data.element || data.property || data.label || 'unknown';

      GoogleAnalytics.event({
        action: 'viewed',
        category: data.component || 'unknown',
        data: {
          label: data.element,
          // Note: it allows us to compute more accurate bounce rate based on data we collect.
          nonInteraction: true,
        },
      });

      // A replica of the event above (which is sent to Universal Analytics) mapped to the format expected by GA4
      GA4Analytics.submit({
        data: {
          ...deriveGA4Dimensions(),
          interaction: 'viewed',
          // eslint-disable-next-line camelcase
          interaction_label: label,
          // eslint-disable-next-line camelcase
          interaction_type: category,
          nonInteraction: true,
          value: data.value,
        },
        name: EVENTS.ECOMMERCE_GA4.USER_INTERACTION,
      });
    });
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe('action', (eventName, data) => {
      const category = data.component || data.category || 'unknown';
      const nonInteraction = includes(category, nonInteractionActions) || data.nonInteraction;
      const action = data.action || 'action';
      const label = data.element || data.property || data.label || 'unknown';
      GoogleAnalytics.event({
        action,
        category,
        data: {
          label,
          nonInteraction,
          value: data.value,
        },
      });

      // A replica of the event above (which is sent to Universal Analytics) mapped to the format expected by GA4
      GA4Analytics.submit({
        data: {
          ...deriveGA4Dimensions(),
          interaction: action,
          // eslint-disable-next-line camelcase
          interaction_label: label,
          // eslint-disable-next-line camelcase
          interaction_type: category,
          value: data.value,
        },
        name: EVENTS.ECOMMERCE_GA4.USER_INTERACTION,
      });
    });
    pubsub.subscribe(
      'ecommerce',
      ecommerceHandler({
        google,
      }),
    );

    // Handle sending ecommerce events to GA4/GTM
    // @ts-expect-error - Automatic, Please fix when editing this file
    pubsub.subscribe(ecommerceGA4Event, (eventName, data) => {
      const state = pubsub.getState();
      ecommerceHandlerGA4(data, state);
    });

    const populateGoogleDimensions = () => {
      const state = pubsub.getState();
      const cart = state.cart || {
        product: {},
      };
      const conversationInfo = selectLivechatConversationInfo(state);
      const customer = state.customer || {};
      const dimensions = {
        ageBracket: getCustomerAgeBracket(customer),
        agentId: conversationInfo.agentId,
        campaignId: conversationInfo.campaignId,
        conversationId: conversationInfo.conversationId,
        engagementId: conversationInfo.engagementId,
        frequency: customer.frequency,
        hospitalTier: cart.product.hospitalTier || null,
        income: customer.income,
        incomeTier: customer.incomeTier,
        incomeType: customer.incomeType,
        paymentMethod: customer.paymentMethod,
        productId: cart.product.id,
        productName: cart.product.name,
        productType: cart.product.type,
        rebate: customer.rebate ? 'true' : 'false',
        scale: customer.scale,
        skillName: conversationInfo.skillName,
        state: customer.state,
        switching: customer.switching,
        visitorId: conversationInfo.visitorId,
      };

      // @ts-expect-error - Automatic, Please fix when editing this file
      forEachUncapped((dimensionValue, dimensionKey) => {
        // @ts-expect-error - Automatic, Please fix when editing this file
        GoogleAnalytics.dimension(DIMENSIONS[dimensionKey], dimensionValue);
      }, dimensions);

      // Populate promo code dimensions
      const promos = selectOffers(state);

      // eslint-disable-next-line lodash-fp/no-unused-result
      flow(
        (item) => merge(item, values(promos)),
        map('code'),
        // @ts-expect-error - Automatic, Please fix when editing this file
        forEachUncapped((code, index) => {
          // @ts-expect-error - Automatic, Please fix when editing this file
          GoogleAnalytics.dimension(DIMENSIONS[`promoCode${index}`], code);
        }),
      )(range(0, 4));
    };

    pubsub.subscribe('profile:rehydrate', () => {
      populateGoogleDimensions();
    });
    pubsub.subscribe('profile:assign', () => {
      populateGoogleDimensions();
    });
    return pubsub;
  }

  // Create mock analytics object for SERVER
  const pubsub = {
    ecommerce: noop,
    ecommerceGA4: noop,
    getState: () => ({}),
    publish: noop,
    subscribe: noop,
    unsubscribe: noop,
  };
  return pubsub;
};

export default getAnalytics();
