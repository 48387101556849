import React from 'react';

import { FormFieldsForComponentProps } from '../../../types';
import { IS_MEMBER_FIELD_OPTIONS, SHARED_FIELD_PROPS } from '../../../constants';
import { LEAD_TYPES } from '../../../../../models/lead-capture/constants';
import FormikField from '../../../../formik-field-with-scroll-margin';
import RequiredFieldsDisclaimer from '../../../../../pages/buy/components/buy-form/components/required-fields-disclaimer';
import styles from '../../../styles.module.sass';

type SaveAndRetrieveQuoteFormFieldsProps = Pick<
  FormFieldsForComponentProps,
  'component' | 'setFieldTouched' | 'setFieldValue'
>;

const SaveAndRetrieveQuoteFormFields = ({
  component,
  setFieldTouched,
  setFieldValue,
}: SaveAndRetrieveQuoteFormFieldsProps) => (
  <>
    <RequiredFieldsDisclaimer />
    <FormikField
      {...SHARED_FIELD_PROPS}
      isRequired
      label="Email address"
      maxLength="255"
      name="email"
      placeholder="name@example.com"
      type="email"
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      className={styles.fieldWithSuffixText}
      isRequired
      label="Name"
      name="name"
      placeholder="Name"
      suffixText="So we can personalise your cover email."
      type="text"
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      name="isMember"
      label="Are you a current ahm member?"
      inputVariant="button"
      type="radioGroup"
      options={IS_MEMBER_FIELD_OPTIONS}
      onBlur={() => setFieldTouched('isMember', true, false)}
      onChange={(isMember: boolean) => setFieldValue('isMember', isMember)}
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      className={styles.fieldWithSuffixText}
      label="Phone (optional)"
      name="phone"
      placeholder="Phone number"
      suffixText="So we can call you to see if you have any questions."
      type="tel"
    />
    <FormikField
      {...SHARED_FIELD_PROPS}
      fieldLabel="Please contact me about health insurance, discounts, promotions from ahm & partners including health and other insurance products and services."
      name={`marketingOptIn-${component}`}
      type="checkbox"
      isRequired={component === LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component}
      variant={component === LEAD_TYPES.REQUEST_A_CALL.component && styles.checkbox}
    />
  </>
);

export default SaveAndRetrieveQuoteFormFields;
