import { get, includes } from 'lodash/fp';
import { LIVE_CHAT_STATUS } from '@ahmdigital/constants';
import { useSelector } from 'react-redux';

import config from '../../../config';
import selectIsAbcSupportedAndAvailable from '../../../selectors/select-is-abc-supported';

const useLiveChat = () => {
  const liveChatStatus = useSelector(get('livechat.status'));
  const isAbcSupportedAndAvailable = useSelector(selectIsAbcSupportedAndAvailable);
  const messageId = config.get('liveChat:appleBusinessChat:messageId');
  const isOnline = includes(liveChatStatus, [LIVE_CHAT_STATUS.BUSY, LIVE_CHAT_STATUS.ONLINE]);

  return { isAbcSupportedAndAvailable, isOnline, messageId };
};

export default useLiveChat;
