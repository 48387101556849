import React from 'react';

import { Indicator, Label, Wrapper } from './styles';

type Props = {
  isOnline: boolean;
};

const LiveChatStatus = ({ isOnline }: Props) => (
  <Wrapper>
    <Indicator isOnline={isOnline} />
    <Label isOnline={isOnline}>We’re {isOnline ? 'online' : 'offline'}</Label>
  </Wrapper>
);

export default LiveChatStatus;
