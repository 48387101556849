import React, { useEffect } from 'react';

import { LEAD_TYPES } from '../../../../models/lead-capture/constants';
import AbandonedCartPreBuyFormFields from './abandoned-cart-pre-buy';
import RequestACallFormFields from './request-a-call';
import SaveAndRetrieveQuoteFormFields from './save-and-retrieve-quote';
import scrollToElementWithId from '../../../../utils/scroll-to-element-with-id';
import type { FormFieldsForComponentProps } from '../../types';

const FormFieldsForComponent = ({ component, setFieldTouched, setFieldValue, values }: FormFieldsForComponentProps) => {
  useEffect(() => {
    if (values?.isMember) {
      // This must be an effect, since it must occur after the new fields are shown (once isMember is true)
      scrollToElementWithId('label-for-is-member');
    }
  }, [values?.isMember]);

  switch (component) {
    case LEAD_TYPES.REQUEST_A_CALL.component:
      return (
        <RequestACallFormFields
          component={component}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          values={values}
        />
      );
    case LEAD_TYPES.SAVE_AND_RETRIEVE_QUOTE.component:
      return (
        <SaveAndRetrieveQuoteFormFields
          component={component}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      );
    case LEAD_TYPES.ABANDONED_CART_PRE_BUY_FORM.component:
      return <AbandonedCartPreBuyFormFields component={component} />;
    default:
      return null;
  }
};

export default FormFieldsForComponent;
