// AB test
export const AB_TEST_UPDATE = 'AB_TEST_UPDATE';

// Accordion
export const ACCORDION_UPDATE_SELECTION = 'ACCORDION_UPDATE_SELECTION';

// Build Your Cover
export const BUILD_YOUR_COVER_SERVICES_CHANGE = 'BUILD_YOUR_COVER_SERVICES_CHANGE';
export const BUILD_YOUR_COVER_STEP_CHANGE = 'BUILD_YOUR_COVER_STEP_CHANGE';

// Cart
export const CLEAR_CART = 'CLEAR_CART';
export const EXTRAS_CHANGE = 'EXTRAS_CHANGE';
export const HOSPITAL_CHANGE = 'HOSPITAL_CHANGE';
export const PRODUCT_CHANGE = 'PRODUCT_CHANGE';

// Customer
export const ADDRESS_CHANGE = 'ADDRESS_CHANGE';
export const CONTINUOUS_COVER_CHANGE = 'CONTINUOUS_COVER_CHANGE';
export const DATE_OF_BIRTH_CHANGE = 'DATE_OF_BIRTH_CHANGE';
export const EMAIL_CHANGE = 'EMAIL_CHANGE';
export const FREQUENCY_CHANGE = 'FREQUENCY_CHANGE';
export const HAS_REFINED_PRICE = 'HAS_REFINED_PRICE';
export const HAS_SET_BASIC_DETAILS_CHANGE = 'HAS_SET_BASIC_DETAILS_CHANGE';
export const HOSPITAL_EXCESS_CHANGE = 'HOSPITAL_EXCESS_CHANGE';
export const INCOME_CHANGE = 'INCOME_CHANGE';
export const INCOME_TIER_CHANGE = 'INCOME_TIER_CHANGE';
export const INCOME_TYPE_CHANGE = 'INCOME_TYPE_CHANGE';
export const LAST_VIEWED_PRODUCT_CHANGE = 'LAST_VIEWED_PRODUCT_CHANGE';
export const PAYMENT_METHOD_CHANGE = 'PAYMENT_METHOD_CHANGE';
export const PRODUCT_TYPE_CHANGE = 'PRODUCT_TYPE_CHANGE';
export const REBATE_CHANGE = 'REBATE_CHANGE';
export const SCALE_CHANGE = 'SCALE_CHANGE';
export const STATE_CHANGE = 'STATE_CHANGE';
export const STATE_CHANGE_DEFAULT = 'STATE_CHANGE_DEFAULT';
export const SWITCHING_CHANGE = 'SWITCHING_CHANGE';

// Feature Toggle
export const ENABLE_FEATURE = 'ENABLE_FEATURE';
export const DISABLE_FEATURE = 'DISABLE_FEATURE';

// Footer
export const FOOTER_ADJUST_FOOTER_LINKS = 'FOOTER_ADJUST_FOOTER_LINKS';
export const FOOTER_SET_VISIBILITY = 'FOOTER_SET_VISIBILITY';

// Prices
export const PRICE_LOAD = 'PRICE_LOAD';
export const PRICE_LOAD_SUCCESS = 'PRICE_LOAD_SUCCESS';
export const PRICE_LOAD_FAIL = 'PRICE_LOAD_FAIL';
export const CHANGE_EFFECTIVE_DATE = 'CHANGE_EFFECTIVE_DATE';

// Products
export const PRODUCT_LOAD = 'PRODUCT_LOAD';
export const PRODUCT_LOAD_SUCCESS = 'PRODUCT_LOAD_SUCCESS';
export const PRODUCT_LOAD_FAIL = 'PRODUCT_LOAD_FAIL';

// Buy
export const BUY_PROGRESS_LOADED = 'BUY_PROGRESS_LOADED';
export const BUY_UPDATE_DATA = 'BUY_UPDATE_DATA';
export const BUY_SUBMIT = 'BUY_SUBMIT';
export const BUY_SUBMIT_SUCCESS = 'BUY_SUBMIT_SUCCESS';
export const BUY_SUBMIT_SUCCESS_TO_AGR = 'BUY_SUBMIT_SUCCESS_TO_AGR';
export const DEPENDANT_DELETE = 'DEPENDANT_DELETE';
export const DEPENDANT_SUBMIT = 'DEPENDANT_SUBMIT';
export const PAYMENT_UPDATE_DATA = 'PAYMENT_UPDATE_DATA';
export const PAYMENT_SUBMIT = 'PAYMENT_SUBMIT';
export const PAYMENT_SUBMIT_SUCCESS = 'PAYMENT_SUBMIT_SUCCESS';
export const PAYMENT_SUBMIT_FAIL = 'PAYMENT_SUBMIT_FAIL';

// Modal
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

// Drawer
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const OPEN_DRAWER = 'OPEN_DRAWER';

// Toast
export const CLOSE_TOAST = 'CLOSE_TOAST';
export const OPEN_TOAST = 'OPEN_TOAST';
export const SET_CONTENT_TOAST = 'SET_CONTENT_TOAST';

// History
export const HISTORY_PUSH = 'HISTORY_PUSH';

// Offers
export const OFFERS_ADD_BY_PROMO_CODE = 'OFFERS_ADD_BY_PROMO_CODE';
export const OFFERS_REMOVE = 'OFFERS_REMOVE';
export const OFFERS_UPDATE = 'OFFERS_UPDATE';

// Location
export const LOCATION_LOAD = 'LOCATION_LOAD';
export const LOCATION_LOAD_SUCCESS = 'LOCATION_LOAD_SUCCESS';
export const LOCATION_LOAD_FAIL = 'LOCATION_LOAD_FAIL';

// Attribution
export const SET_ATTRIBUTION = 'SET_ATTRIBUTION';
export const SET_HAS_PARTNER_REFERRER = 'SET_HAS_PARTNER_REFERRER';

// Product Listing Overlay
export const PRODUCT_LISTING_OVERLAY_SHOWING = 'PRODUCT_LISTING_OVERLAY_SHOWING';
export const PRODUCT_LISTING_OVERLAY_HIDDEN = 'PRODUCT_LISTING_OVERLAY_HIDDEN';

// Product comparer
export const PRODUCT_COMPARER_ACTIVE_INDEX = 'PRODUCT_COMPARER_ACTIVE_INDEX';
export const PRODUCT_COMPARER_DIRECT_COMPARISON_TOGGLE = 'PRODUCT_COMPARER_DIRECT_COMPARISON_TOGGLE';
export const PRODUCT_COMPARER_DIRECT_COMPARISON_PRODUCT_CHANGE = 'PRODUCT_COMPARER_DIRECT_COMPARISON_PRODUCT_CHANGE';

// Referral
export const REFERRAL_SET_CODE = 'REFERRAL_SET_CODE';
export const REFERRAL_SET_TARGET_SEGMENT = 'REFERRAL_SET_TARGET_SEGMENT';
export const REFERRAL_RESET = 'REFERRAL_RESET';
export const REFERRAL_SET_URL = 'REFERRAL_SET_URL';

// Choosable
export const CHOOSABLE_SET_COMPARER_PRODUCT_ID = 'CHOOSABLE_SET_COMPARER_PRODUCT_ID';
export const CHOOSABLE_RESET_COMPARER_PRODUCT_ID = 'CHOOSABLE_RESET_COMPARER_PRODUCT_ID';

// Request A Call
export const REQUEST_A_CALL_CAPTURE = 'REQUEST_A_CALL_CAPTURE';
export const REQUEST_A_CALL_COLLAPSE = 'REQUEST_A_CALL_COLLAPSE';
export const REQUEST_A_CALL_DISMISS = 'REQUEST_A_CALL_DISMISS';
export const REQUEST_A_CALL_EXPAND = 'REQUEST_A_CALL_EXPAND';
export const REQUEST_A_CALL_FAIL = 'REQUEST_A_CALL_FAIL';
export const REQUEST_A_CALL_RESET = 'REQUEST_A_CALL_RESET';
export const REQUEST_A_CALL_RULE_BLOCK = 'REQUEST_A_CALL_RULE_BLOCK';
export const REQUEST_A_CALL_RULE_TRIGGER = 'REQUEST_A_CALL_RULE_TRIGGER';
export const REQUEST_A_CALL_SHOW = 'REQUEST_A_CALL_SHOW';
export const REQUEST_A_CALL_SUCCESS = 'REQUEST_A_CALL_SUCCESS';

// Save and Retrieve Quote
export const SAVE_AND_RETRIEVE_QUOTE_FAIL = 'SAVE_AND_RETRIEVE_QUOTE_FAIL';
export const SAVE_AND_RETRIEVE_QUOTE_RESET = 'SAVE_AND_RETRIEVE_QUOTE_RESET';
export const SAVE_AND_RETRIEVE_QUOTE_SUCCESS = 'SAVE_AND_RETRIEVE_QUOTE_SUCCESS';
